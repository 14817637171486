import { useEffect, useState } from "react";
import ArticleCard from "../../component/ArticleCard";
import { Container, Grid, Skeleton, Box, Paper, Button, Typography } from "@mui/material";

interface Article {
  id: string;
  headline: string;
  metaDescription: string;
  slug: string;
  image?: string;
  readingTime?: number;
  createdAt: string;
  tags: Array<{
    id: string;
    title: string;
    slug: string;
  }>;
  category: {
    id: string;
    title: string;
    slug: string;
  };
}

async function getPosts(page: number) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/get-articals?page=${page}&limit=10`
    );
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching posts:", error);
    return { articles: [], total: 0 };
  }
}

const Blog = () => {
  const [posts, setPosts] = useState<Article[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const fetchPosts = async (page: number) => {
    setLoading(true);
    try {
      const { total, articles } = await getPosts(page);
      setPosts(articles);
      setLastPage(Math.ceil(total / 10));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts(pageNumber);
  }, [pageNumber]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={3}>
        {loading
          ? [1, 2, 3].map((n) => (
              <Grid item xs={12} key={n}>
                <Box sx={{ width: "100%", mb: 2 }}>
                  <Skeleton
                    variant="rectangular"
                    height={200}
                    sx={{ borderRadius: 2, mb: 2 }}
                  />
                  <Skeleton
                    variant="text"
                    height={40}
                    width="80%"
                    sx={{ mb: 1 }}
                  />
                  <Skeleton variant="text" height={20} width="60%" />
                </Box>
              </Grid>
            ))
          : posts.map((article) => (
              <ArticleCard key={article.id} article={article} />
            ))}
      </Grid>

      {lastPage > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2, mt: 4 }}>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              p: 1,
              bgcolor: "background.paper",
              borderRadius: 2,
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => setPageNumber((p) => Math.max(0, p - 1))}
              disabled={pageNumber === 0}
              sx={{
                minWidth: 100,
                borderRadius: 2,
              }}
            >
              Previous
            </Button>
            <Typography variant="body2" sx={{ px: 2, color: "text.secondary" }}>
              Page {pageNumber + 1} of {lastPage}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setPageNumber((p) => Math.min(lastPage - 1, p + 1))}
              disabled={pageNumber === lastPage - 1}
              sx={{
                minWidth: 100,
                borderRadius: 2,
              }}
            >
              Next
            </Button>
          </Paper>
        </Box>
      )}
    </Container>
  );
};

export default Blog;
