import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import {
  Container,
  Typography,
  Chip,
  Box,
  Paper,
  Skeleton,
  Alert,
  Divider,
  styled,
  Link,
} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FolderIcon from "@mui/icons-material/Folder";

type MarkdownComponentProps = {
  children?: React.ReactNode;
  [key: string]: any;
};

type CodeComponentProps = MarkdownComponentProps & {
  inline?: boolean;
};

interface Article {
  headline: string;
  markdown: string;
  html: string;
  metaDescription: string;
  metaKeywords: string;
  tags: Array<{
    id: string;
    title: string;
    slug: string;
  }>;
  category: {
    id: string;
    title: string;
    slug: string;
  };
}

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: "16px",
  fontWeight: 500,
  fontSize: "0.75rem",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[100],
  "& .MuiChip-label": {
    padding: "0 12px",
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  "& .prose": {
    color: theme.palette.text.primary,
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      color: theme.palette.text.primary,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    "& p": {
      marginBottom: theme.spacing(2),
      lineHeight: 1.7,
    },
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    "& ul, & ol": {
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
    },
    "& li": {
      marginBottom: theme.spacing(1),
    },
    "& blockquote": {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      paddingLeft: theme.spacing(2),
      marginLeft: 0,
      marginRight: 0,
      fontStyle: "italic",
      color: theme.palette.text.secondary,
    },
    "& code": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[800]
          : theme.palette.grey[100],
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.shape.borderRadius,
      fontSize: "0.875em",
    },
    "& pre": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[900]
          : theme.palette.grey[100],
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      overflow: "auto",
      "& code": {
        backgroundColor: "transparent",
        padding: 0,
      },
    },
    "& table": {
      width: "100%",
      marginBottom: theme.spacing(3),
      borderCollapse: "collapse",
      borderSpacing: 0,
      overflow: "hidden",
      "& th, & td": {
        padding: theme.spacing(1.5),
        borderBottom: `1px solid ${
          theme.palette.mode === "dark"
            ? theme.palette.grey[800]
            : theme.palette.grey[200]
        }`,
        textAlign: "left",
      },
      "& th": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.grey[900]
            : theme.palette.grey[100],
        fontWeight: 600,
        color: theme.palette.text.primary,
      },
      "& tr:nth-of-type(even)": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.grey[900]
            : theme.palette.grey[50],
      },
      "& tr:hover": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.grey[800]
            : theme.palette.grey[100],
      },
    },
    "& thead": {
      borderBottom: `2px solid ${
        theme.palette.mode === "dark"
          ? theme.palette.grey[700]
          : theme.palette.grey[300]
      }`,
    },
    "& tbody tr:last-child td": {
      borderBottom: "none",
    },
  },
}));

const ArticleDetail = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/get-articals/${slug}`
        );
        const data = await res.json();
        setArticle(data);
      } catch (error) {
        console.error("Error fetching article:", error);
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchArticle();
    }
  }, [slug]);

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box sx={{ mb: 4 }}>
          <Skeleton variant="text" height={60} width="80%" sx={{ mb: 2 }} />
          <Skeleton variant="text" height={24} width="40%" sx={{ mb: 3 }} />
          <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
            <Skeleton variant="rounded" width={80} height={32} />
            <Skeleton variant="rounded" width={80} height={32} />
            <Skeleton variant="rounded" width={80} height={32} />
          </Box>
          <Skeleton variant="rectangular" height={200} sx={{ mb: 2 }} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} width="95%" />
          <Skeleton variant="text" height={20} width="90%" />
        </Box>
      </Container>
    );
  }

  if (!article) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          Article not found
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={0} sx={{ p: { xs: 2, md: 4 }, bgcolor: "transparent" }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "2rem", md: "3rem" },
            fontWeight: 700,
            mb: 3,
            lineHeight: 1.2,
          }}
        >
          {article.headline}
        </Typography>

        <Box sx={{ display: "flex", gap: 1, mb: 3, flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <FolderIcon sx={{ fontSize: "1rem", color: "text.secondary" }} />
            <Typography variant="body2" color="text.secondary">
              {article.category.title}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <LocalOfferIcon
              sx={{ fontSize: "1rem", color: "text.secondary" }}
            />
            {article.tags.map((tag) => (
              <StyledChip key={tag.id} label={tag.title} size="small" />
            ))}
          </Box>
        </Box>

        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ mb: 4, fontStyle: "italic" }}
        >
          {article.metaDescription}
        </Typography>

        <Divider sx={{ mb: 4 }} />

        <ContentContainer>
          {/* HTML Content */}
          {/* {article.html && (
            <div
              className="prose dark:prose-invert max-w-none"
              dangerouslySetInnerHTML={{ __html: article.html }}
            />
          )} */}

          {/* Markdown Content */}
          {article.markdown && (
            <div className="prose dark:prose-invert max-w-none">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                  p: ({ children, ...props }) => {
                    // Handle array of children for iframe syntax
                    if (Array.isArray(children) && children.length === 3) {
                      const [start, middle, end] = children;
                      if (
                        typeof start === "string" &&
                        start.trim() === "::: @iframe" &&
                        typeof end === "string" &&
                        end.trim() === ":::" &&
                        middle &&
                        typeof middle === "object" &&
                        "props" in middle
                      ) {
                        // Extract URL from the middle element's text content
                        const url = middle.props?.children || "";
                        return (
                          <Box
                            sx={{
                              position: "relative",
                              width: "100%",
                              paddingTop: "56.25%", // 16:9 aspect ratio
                              mb: 3,
                              "& iframe": {
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                border: "none",
                                borderRadius: 1,
                                bgcolor: "background.paper",
                              },
                            }}
                          >
                            <iframe
                              src={url}
                              allowFullScreen
                              loading="lazy"
                              title="Embedded content"
                            />
                          </Box>
                        );
                      }
                    }

                    // Regular paragraph
                    return (
                      <div
                        style={{ marginBottom: 2, lineHeight: 1.7 }}
                        {...props}
                      >
                        {children}
                      </div>
                    );
                  },
                  h1: ({ children }) => (
                    <Typography
                      component="h1"
                      variant="h1"
                      sx={{
                        fontSize: { xs: "2rem", md: "2.5rem" },
                        fontWeight: 700,
                        mb: 3,
                      }}
                    >
                      {children}
                    </Typography>
                  ),
                  h2: ({ children }) => (
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{
                        fontSize: { xs: "1.75rem", md: "2rem" },
                        fontWeight: 600,
                        mb: 2,
                      }}
                    >
                      {children}
                    </Typography>
                  ),
                  h3: ({ children }) => (
                    <Typography
                      component="h3"
                      variant="h3"
                      sx={{
                        fontSize: { xs: "1.5rem", md: "1.75rem" },
                        fontWeight: 600,
                        mb: 2,
                      }}
                    >
                      {children}
                    </Typography>
                  ),

                  a: ({ href, children }) => (
                    <Link
                      href={href}
                      color="primary"
                      target="_blank"
                      sx={{
                        textDecoration: "none",
                        "&:hover": { textDecoration: "underline" },
                      }}
                    >
                      {children}
                    </Link>
                  ),
                  blockquote: ({ children }) => (
                    <Box
                      component="blockquote"
                      sx={{
                        borderLeft: (theme) =>
                          `4px solid ${theme.palette.primary.main}`,
                        pl: 2,
                        my: 2,
                        color: "text.secondary",
                        fontStyle: "italic",
                      }}
                    >
                      {children}
                    </Box>
                  ),
                  code: ({ inline, children }: CodeComponentProps) => {
                    if (inline) {
                      return (
                        <Box
                          component="code"
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? theme.palette.grey[800]
                                : theme.palette.grey[100],
                            p: 0.5,
                            borderRadius: 1,
                            fontSize: "0.875em",
                          }}
                        >
                          {children}
                        </Box>
                      );
                    }
                    return (
                      <Box
                        component="pre"
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? theme.palette.grey[900]
                              : theme.palette.grey[100],
                          p: 2,
                          borderRadius: 1,
                          overflow: "auto",
                          "& code": {
                            backgroundColor: "transparent",
                            p: 0,
                          },
                        }}
                      >
                        <Box component="code">{children}</Box>
                      </Box>
                    );
                  },
                }}
              >
                {article.markdown}
              </ReactMarkdown>
            </div>
          )}
        </ContentContainer>
      </Paper>
    </Container>
  );
};

export default ArticleDetail;
