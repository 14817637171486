import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Chip, Box, CardActionArea, Stack, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FolderIcon from '@mui/icons-material/Folder';

interface ArticleCardProps {
  article: {
    id: string;
    headline: string;
    metaDescription: string;
    slug: string;
    image?: string;
    readingTime?: number;
    createdAt: string;
    tags: Array<{
      id: string;
      title: string;
      slug: string;
    }>;
    category: {
      id: string;
      title: string;
      slug: string;
    };
  };
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8],
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: '16px',
  fontWeight: 500,
  fontSize: '0.75rem',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
  '& .MuiChip-label': {
    padding: '0 12px',
  },
}));

const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => {

  return (
    <Grid item xs={12} component={Link} to={`/blog/${article.slug}`} style={{ textDecoration: 'none' }}>
      <StyledCard>
        <CardActionArea>
          {article.image && (
            <CardMedia
              component="img"
              height="200"
              image={article.image}
              alt={article.headline}
              sx={{
                objectFit: 'cover',
              }}
            />
          )}
          <CardContent sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h5" component="h2" gutterBottom sx={{
              fontWeight: 700,
              fontSize: '1.5rem',
              lineHeight: 1.3,
              mb: 2,
              color: 'text.primary',
            }}>
              {article.headline}
            </Typography>
            
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
              {article.metaDescription}
            </Typography>
            
            <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
              {article.tags.slice(0, 3).map(tag => (
                <StyledChip
                  key={tag.id}
                  label={tag.title}
                  size="small"
                />
              ))}
            </Stack>
            
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              mt: 'auto',
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <FolderIcon sx={{ fontSize: '1rem', color: 'text.secondary' }} />
                <Typography variant="caption" color="text.secondary">
                  {article.category.title}
                </Typography>
              </Box>
              
              {article.readingTime && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <AccessTimeIcon sx={{ fontSize: '1rem', color: 'text.secondary' }} />
                  <Typography variant="caption" color="text.secondary">
                    {article.readingTime} min read
                  </Typography>
                </Box>
              )}
            </Box>
          </CardContent>
        </CardActionArea>
      </StyledCard>
    </Grid>
  );
};

export default ArticleCard;
