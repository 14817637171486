import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { COLORS } from "../../common/color";

import logo from "../../assets/iHomies-img/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
  getFirestore,
} from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
} from "firebase/auth";
import { useForm } from "react-hook-form";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import EastIcon from "@mui/icons-material/East";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";

import Tooltip from "@mui/material/Tooltip";

import { Link } from "react-scroll";

import { PATH } from "../../constant";
import toast from "react-hot-toast";
import { useStoreActions, useStoreState } from "easy-peasy";
import { ReduxStoreI } from "../../store/redux-store";
import { useAuth } from "../../context/AuthContext";
type Anchor = "right";

function IHomiesHeader() {
  const [loading, setLoading] = useState(false);
  const [openDilog, setOpenDilog] = React.useState(false);
  const [currentUserName, setCurrentUserName] = useState("");
  const db = getFirestore();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState<any>(null);
  let uid = "";
  const [selectedTab, setSelectedTab] = useState("");

  const handleTabClick = (tab: any) => {
    setSelectedTab(tab);
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchAuthAndRole = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const firmsRef = firebase
            .firestore()
            .collection("users")
            .doc(user.uid);
          const doc = await firmsRef.get();

          if (doc.exists) {
            const userData = doc.data();
            let userRole = userData?.role;

            if (!userRole) {
              userRole = ["user"];
            } else if (!Array.isArray(userRole)) {
              userRole = [userRole];
            }

            setRole(userRole);
          } else {
            throw new Error("User document does not exist");
          }
        } else {
          setRole(null); // Reset the role state if no user is logged in
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching auth state and role:", error);
      }
    };

    const unsubscribe = getAuth().onAuthStateChanged(() => {
      fetchAuthAndRole();
    });

    return () => unsubscribe();
  }, []);

  const { payment } = useStoreState((state: ReduxStoreI) => state.Payment);
  const { getPayment, setPayment } = useStoreActions(
    (actions: ReduxStoreI) => actions.Payment
  );

  const handleContactClick = () => {
    window.location.href = "mailto:info@financeprep.io";
  };
  const goToSubscription = () => {
    navigate(PATH.pricing);
  };
  const goToQuestion = () => {
    navigate(PATH.question);
  };

  const goToHome = () => {
    navigate(PATH.home);
  };

  const goToPrice = () => {
    navigate(PATH.pricing);
  };

  const goToTestimonials = () => {
    navigate(PATH.testimonials);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const fatchUserName = async () => {
    try {
      const db = getFirestore();
      const userDocRef = doc(db, "users", uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setValue("userName", userData.name);
        setCurrentUserName(userData.name);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const handleClickOpenDilog = () => {
    setOpenDilog(true);
  };

  const handleCloseDilog = () => {
    setOpenDilog(false);
    // reset();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const displayName = data.name; // Assign the value of data.name to displayName
        await updateProfile(user, { displayName }); // Update the display name
        const docRef = doc(db, "users", user.uid);
        await setDoc(docRef, { name: data.userName }, { merge: true }); // Update display name in Firestore
        toast.success("Name Upadated Sucessfully", {
          duration: 4000,
          position: "bottom-right",

          // Styling
          style: {},
          className: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });
        setLoading(false);
        fatchUserName(); // Fetch and update the user's display name
      }
      setOpenDilog(false); // Close the dialog after updating
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(async (user) => {
      if (user) {
        uid = user?.uid;
        try {
          const db = getFirestore();
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setCurrentUserName(userData.name);
            setValue("userName", userData.name);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
      setIsLoggedIn(!!user); // Set isLoggedIn based on whether user exists
    });

    return () => unsubscribe(); // Clean up subscription
  }, []);

  const onLogoutClick = async () => {
    try {
      const auth = getAuth();
      await auth.signOut(); // Sign out the user

      // Reset state
      setAnchorEl(null);
      setState({ right: false });
      setPayment({});
      setRole(null); // Reset the role state
      localStorage.clear();
      goToHome();

      toast.success("Logged out Successfully", {
        duration: 4000,
        position: "bottom-right",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const { onGoogleClick } = useAuth();

  // const onGoogleClick = async () => {
  //   try {
  //     const auth = getAuth();
  //     const provider = new GoogleAuthProvider();
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;
  //     localStorage.setItem("auth", JSON.stringify(user));
  //     setState({ right: false });
  //     toast.success("Login Sucessfully", {
  //       duration: 4000,
  //       position: "bottom-right",

  //       // Styling
  //       style: {},
  //       className: "",

  //       // Change colors of success/error/loading icon
  //       iconTheme: {
  //         primary: "#000",
  //         secondary: "#fff",
  //       },
  //     });

  //     // Check if user email already exists
  //     const docRef = doc(db, "users", user.uid);
  //     const docSnap = await getDoc(docRef);

  //     // If user doesn't exist, save in database
  //     if (!docSnap.exists()) {
  //       await setDoc(doc(db, "users", user.uid), {
  //         name: user.displayName,
  //         email: user.email,
  //         timestamp: serverTimestamp(),
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const location = useLocation();

  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      sx={{ width: "300px", display: "flex", flexDirection: "column" }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end !important",
          marginTop: "20px",
        }}
      >
        <Button
          onClick={toggleDrawer(anchor, false)}
          sx={{
            backgroundColor: COLORS.white,
            width: "fit-content",
            color: COLORS.black,
            marginRight: "20px",
            "&:hover": {
              backgroundColor: `${COLORS.black} !important`,
              color: `${COLORS.white} !important`,
            },
          }}
        >
          <CloseIcon />
        </Button>
      </Box>

      <List sx={{ marginTop: "30px" }}>
        {isLoggedIn ? (
          <>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setState({ right: false });
                  handleClickOpenDilog();
                }}
              >
                <ListItemText primary={"Profile"} />
              </ListItemButton>
            </ListItem>

            {role == "admin" && (
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    navigate({ pathname: PATH.admin });
                    setState({ right: false });
                  }}
                >
                  <ListItemText primary={"Admin"} />
                </ListItemButton>
              </ListItem>
            )}

            <ListItem>
              <ListItemButton
                onClick={() => {
                  toggleDrawer(anchor, false);
                  goToSubscription();
                }}
              >
                <ListItemText primary={"My Subscription"} />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  toggleDrawer(anchor, false);
                  goToTestimonials();
                }}
              >
                <ListItemText primary={"Testimonials"} />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  toggleDrawer(anchor, false);
                  goToQuestion();
                }}
              >
                <ListItemText primary={"Question Lab"} />
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <>
            {" "}
            <Link
              onClick={() => {
                navigate(
                  {
                    pathname: PATH.home,
                  },
                  {
                    state: {
                      scroll: true,
                    },
                  }
                );
              }}
              to={"why-us-scroll"}
              offset={-100}
              spy
              smooth
            >
              {" "}
              <ListItem>
                <ListItemButton onClick={toggleDrawer(anchor, false)}>
                  <ListItemText primary={"Why Us ?"} />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              onClick={() => {
                navigate(
                  {
                    pathname: PATH.home,
                  },
                  {
                    state: {
                      scroll: true,
                    },
                  }
                );
              }}
              to={"pricing-scroll"}
              offset={-100}
              spy
              smooth
            >
              {" "}
              <ListItem>
                <ListItemButton onClick={toggleDrawer(anchor, false)}>
                  <ListItemText primary={"Pricing"} />
                </ListItemButton>
              </ListItem>
            </Link>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  toggleDrawer(anchor, false);
                  handleContactClick();
                }}
              >
                <ListItemText primary={"Contact Us"} />
              </ListItemButton>
            </ListItem>
            <Link
              onClick={() => {
                navigate(
                  {
                    pathname: PATH.home,
                  },
                  {
                    state: {
                      scroll: true,
                    },
                  }
                );
              }}
              to={"testimonials-scroll"}
              offset={-100}
              spy
              smooth
            >
              {" "}
              <ListItem>
                <ListItemButton onClick={toggleDrawer(anchor, false)}>
                  <ListItemText primary={"Testimonials"} />
                </ListItemButton>
              </ListItem>
            </Link>
          </>
        )}
      </List>
      <Divider />

      <List>
        {!isLoggedIn ? (
          <ListItem>
            <ListItemButton onClick={onGoogleClick}>
              <ListItemText primary={"Join Now"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem>
            <ListItemButton onClick={onLogoutClick}>
              <ListItemText primary={"Log out"} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <>
      <Dialog
        open={openDilog}
        onClose={handleCloseDilog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {"Your Profile"}{" "}
          <Button
            onClick={handleCloseDilog}
            sx={{
              backgroundColor: COLORS.white,
              width: "fit-content",
              color: COLORS.black,

              "&:hover": {
                backgroundColor: `${COLORS.black} !important`,
                color: `${COLORS.white} !important`,
              },
            }}
          >
            <CloseIcon />
          </Button>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ width: "100%" }}>
            <TextField
              sx={{ mt: 2 }}
              label="Name"
              // value={currentUserName}
              {...register("userName", {
                required: true,
              })}
              placeholder="Enter Your Name"
              fullWidth
            />
            {errors.userName && errors.userName.type === "required" && (
              <p className="errorMsg" style={{ color: "red" }}>
                Name is required
              </p>
            )}
          </DialogContent>
          <DialogActions sx={{ marginRight: 2 }}>
            <Button
              type="submit"
              sx={{
                backgroundColor: COLORS.black,
                color: COLORS.white,

                "&:hover": {
                  backgroundColor: `${COLORS.black} !important`,
                  color: `${COLORS.white} !important`,
                },

                textTransform: "none",
              }}
            >
              {loading ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Box sx={{ bgcolor: "#F3F7FB" }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              width: "100%",
              padding: "24px 0px",
              height: "10vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                onClick={goToHome}
                component="img"
                sx={{
                  width: "200px",
                  cursor: "pointer",
                }}
                src={logo}
              ></Box>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 4,
                }}
              >
                {!isLoggedIn ? (
                  <>
                    <Link
                      className={`underline-hover ${
                        selectedTab === "why-us-scroll" ? "selected" : ""
                      }`}
                      onClick={() => {
                        handleTabClick("why-us-scroll");
                        navigate(
                          {
                            pathname: PATH.home,
                          },
                          {
                            state: {
                              scroll: true,
                            },
                          }
                        );
                      }}
                      to={"why-us-scroll"}
                      offset={-100}
                      spy
                      smooth
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "13px",
                          color: "#485687",
                          fontFamily: "Poppins",
                        }}
                      >
                        Why Us?
                      </Typography>
                    </Link>
                    <Link
                      className={`underline-hover ${
                        selectedTab === "pricing-scroll" ? "selected" : ""
                      }`}
                      onClick={() => {
                        handleTabClick("pricing-scroll");
                        navigate(
                          {
                            pathname: PATH.home,
                          },
                          {
                            state: {
                              scroll: true,
                            },
                          }
                        );
                      }}
                      to={"pricing-scroll"}
                      offset={-100}
                      spy
                      smooth
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "13px",
                          color: "#485687",
                          fontFamily: "Poppins",
                        }}
                      >
                        Pricing
                      </Typography>
                    </Link>
                    <Link
                      className={`underline-hover ${
                        selectedTab === "testimonials-scroll" ? "selected" : ""
                      }`}
                      onClick={() => {
                        handleTabClick("testimonials-scroll");
                        navigate(
                          {
                            pathname: PATH.home,
                          },
                          {
                            state: {
                              scroll: true,
                            },
                          }
                        );
                      }}
                      to={"testimonials-scroll"}
                      offset={-100}
                      spy
                      smooth
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "13px",
                          color: "#485687",
                          fontFamily: "Poppins",
                        }}
                      >
                        Testimonials
                      </Typography>
                    </Link>
                  </>
                ) : (
                  <>
                    <Typography
                      className={`underline-hover ${
                        selectedTab === "pricing" ? "selected" : ""
                      }`}
                      onClick={() => {
                        handleTabClick("pricing");
                        goToPrice();
                      }}
                      sx={{
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#485687",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                    >
                      Pricing
                    </Typography>
                    <Typography
                      className={`underline-hover ${
                        selectedTab === "testimonials" ? "selected" : ""
                      }`}
                      onClick={() => {
                        handleTabClick("testimonials");
                        goToTestimonials();
                      }}
                      sx={{
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#485687",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                    >
                      Testimonials
                    </Typography>
                    <Typography
                      className={`underline-hover ${
                        selectedTab === "questionlab" ? "selected" : ""
                      }`}
                      onClick={() => {
                        handleTabClick("questionlab");
                        goToQuestion();
                      }}
                      sx={{
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#485687",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                    >
                      Question Lab
                    </Typography>
                  </>
                )}

                <Typography
                  className={`underline-hover ${
                    selectedTab === "contact-us" ? "selected" : ""
                  }`}
                  sx={{
                    fontWeight: 600,
                    fontSize: "13px",
                    color: "#485687",
                    cursor: "pointer",
                    fontFamily: "Poppins",
                  }}
                  onClick={() => {
                    handleTabClick("contact-us");
                    handleContactClick();
                  }}
                >
                  Contact-Us
                </Typography>
                <Typography
                  className={`underline-hover ${
                    selectedTab === "blog" ? "selected" : ""
                  }`}
                  sx={{
                    fontWeight: 600,
                    fontSize: "13px",
                    color: "#485687",
                    cursor: "pointer",
                    fontFamily: "Poppins",
                  }}
                  onClick={() => {
                    handleTabClick("blog");
                    navigate({ pathname: PATH.blog });
                  }}
                >
                  Blogs
                </Typography>
                {role == "admin" && (
                  <Typography
                    className={`underline-hover ${
                      selectedTab === "admin" ? "selected" : ""
                    }`}
                    sx={{
                      fontWeight: 600,
                      fontSize: "13px",
                      color: "#485687",
                      cursor: "pointer",
                      fontFamily: "Poppins",
                    }}
                    onClick={() => {
                      handleTabClick("admin");
                      navigate({ pathname: PATH.admin });
                    }}
                  >
                    Admin
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {!isLoggedIn ? (
                  <Button
                    sx={{
                      backgroundColor: "none",
                      color: "#2B57F4",
                      fontSize: "13px",
                      fontWeight: 600,
                      textTransform: "none",
                      border: "1px solid #2B57F4",
                      borderRadius: "50px",
                      px: "15px",
                      fontFamily: "Poppins",
                    }}
                    onClick={onGoogleClick}
                  >
                    Join Now
                  </Button>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip title="Account settings">
                        <IconButton
                          onClick={handleClick}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <Avatar sx={{ width: 32, height: 32 }}>
                            {currentUserName.charAt(0)}
                          </Avatar>
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem onClick={handleClickOpenDilog}>
                        <ListItemIcon>
                          <PermIdentityIcon fontSize="small" />
                        </ListItemIcon>
                        Profile
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          goToSubscription();
                        }}
                      >
                        <ListItemIcon>
                          <SubscriptionsIcon fontSize="small" />
                        </ListItemIcon>{" "}
                        My Subscription
                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={onLogoutClick}>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Log out
                      </MenuItem>
                    </Menu>
                  </>
                )}

                {/* <Button
                  sx={{
                    backgroundColor: "none",
                    color: "#2B57F4",
                    fontSize: "13px",
                    fontWeight: 600,
                    textTransform: "none",
                    border: "1px solid #2B57F4",
                    borderRadius: "50px",
                    px: "15px",
                  }}
                >
                  Join now
                </Button> */}
              </Box>
              <Box sx={{ display: { md: "none" } }}>
                {(["right"] as const).map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      onClick={toggleDrawer(anchor, true)}
                      sx={{
                        backgroundColor: COLORS.white,
                        color: COLORS.black,
                        "&:hover": {
                          backgroundColor: `${COLORS.black} !important`,
                          color: `${COLORS.white} !important`,
                        },
                      }}
                    >
                      <MenuIcon />
                    </Button>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </Box>
            </Box>
          </Box>{" "}
        </Container>
      </Box>
    </>
  );
}

export default IHomiesHeader;
